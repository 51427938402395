import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hello, guys it's my 1st react app and it's updated, again i update this.... again update this
        </p>

      </header>
    </div>
  );
}

export default App;
